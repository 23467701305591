import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { SettingsInterface } from './settings.interface';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { SettingsService } from '@shared/services/settings.service';
import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { map, Observable } from 'rxjs';

@Persistence()
@StateRepository()
@State({
  name: 'settings',
  defaults: {},
})
@Injectable({ providedIn: 'any' })
export class SettingsState extends NgxsDataRepository<SettingsInterface> {
  constructor(
    private settingsService: SettingsService,
    private httpClient: HttpClient,
  ) {
    super();
  }

  @Computed()
  get orderString(): string {
    return this.snapshot.bingo_question_elements_order ?? 'qta';
  }

  @Computed()
  get maxTeacherGamesCount(): number {
    return this.snapshot.max_teacher_games_count ?? 1;
  }

  @Computed()
  get maxTeacherPublishedGamesCount(): number {
    return this.snapshot.max_teacher_published_games_count ?? 1;
  }

  loadSettings(): Observable<SettingsInterface> {
    return this.settingsService.getSettings().pipe(
      map((settings) => {
        const so: Record<string, string> = {};
        settings.forEach((s) => {
          so[s.key] = s.value;
        });
        const sets: SettingsInterface = {
          max_teacher_games_count: Number(so['max_teacher_games_count'] ?? 1),
          max_teacher_published_games_count: Number(so['max_teacher_published_games_count'] ?? 1),
          bingo_question_elements_order: so['bingo_question_elements_order'] ?? 'qta',
          max_teacher_students_per_game: Number(so['max_teacher_students_per_game']) ?? 2,
          max_admin_students_per_game: Number(so['max_admin_students_per_game']) ?? 2,
        };
        this.setState(sets);
        return sets;
      }),
    );
  }

  LoadSettings() {
    this.settingsService.getSettings().subscribe((settings) => {
      const so: Record<string, string> = {};
      settings.forEach((s) => {
        so[s.key] = s.value;
      });
      const sets: SettingsInterface = {
        max_teacher_games_count: Number(so['max_teacher_games_count'] ?? 1),
        max_teacher_published_games_count: Number(so['max_teacher_published_games_count'] ?? 1),
        bingo_question_elements_order: so['bingo_question_elements_order'] ?? 'qta',
        max_teacher_students_per_game: Number(so['max_teacher_students_per_game']) ?? 2,
        max_admin_students_per_game: Number(so['max_admin_students_per_game']) ?? 2,
      };
      this.setState(sets);
      return sets;
    });
  }

  updateSetting(key: string, value: string | number) {
    return this.settingsService.updateSetting(key, value).subscribe(() => {
      this.ctx.patchState({ [key]: value });
    });
  }
}
